export default class GeneralSetting {
  constructor(data = {}) {
    this.id = data.id || "";
    this.sk = data.sk || "";
    this.timeIn = data.timeIn || false;
    this.timeOut = data.timeOut || false;
    this.orderNumber = data.orderNumber || false;
    this.cashierName = data.cashierName || false;
    this.modifier = data.modifier || false;
    this.employee = data.employee || false;
    this.memo = data.memo || false;
    this.guestCount = data.guestCount || false;
    this.saleUnitItem = data.saleUnitItem || false;
    this.favorite = data.favorite || false;
    this.takeAway = data.takeAway || false;
    this.booking = data.booking || false;
    this.userPin = data.userPin || false;
    this.sessionPin = data.sessionPin || false;
    this.allowNFCCard = data.allowNFCCard || false;
    this.allowSplitItem = data.allowSplitItem || false;
    this.allowDeliveryNote = data.allowDeliveryNote || false;
    this.kitchenKitCategories = data.kitchenKitCategories || [];
    this.allowKitchenKit = data.allowKitchenKit || false;
    this.printOrderCate = data.printOrderCate || [];
    this.printOrder = data.printOrder || false;
    this.cancelReasons = data.cancelReasons || [];
    this.allowCancelReason = data.allowCancelReason || false;
    this.decimal = data.decimal || 0;
    this.appNature = data.appNature || "Retail";
    this.screenDisplay = data.screenDisplay || "Surface";
    this.defaultInvType = data.defaultInvType || "normal";
    this.receiptTemplate = data.receiptTemplate || "80mm";
    this.template = data.template || {
      allowLogo: false,
      allowTitle: false,
      title: "វិក្កយបត្រ (Invoice)",
      allowVat: false,
      allowAddress: false,
      allowPhone: false,
      allowTNum: false,
      allowDate: false,
      allowStore: false,
      allowCounter: false,
      allowCashier: false,
      allowExRate: false,
      allowUom: false,
      allowDis: false,
      allowAmt: false,
      allowQty: false,
      allowPrice: false,
      allowTax: false,
      allowPaid: false,
      note: "",
      wifiPwd: "",
      thankWord: "",
      lang: 1,
      allowPrintTxn: false,
      mobileDevice: false,
      printAmt: 1,
      timeIn: false,
      timeOut: false,
      customerType: false,
      orderNum: false,
      allowChange: false,
      allowSignature: false,
      allowReciept: false,
      quickPrint: false
    };
    this.comtemplate = data.comtemplate || {
      allowLogo: false,
      allowTitle: false,
      title: "វិក្កយបត្រ (Invoice)",
      allowVat: false,
      allowAddress: false,
      allowPhone: false,
      allowTNum: false,
      allowDate: false,
      allowStore: false,
      allowCounter: false,
      allowCashier: false,
      allowExRate: false,
      allowUom: false,
      allowDis: false,
      allowAmt: false,
      allowQty: false,
      allowPrice: false,
      allowTax: false,
      allowPaid: false,
      note: "",
      wifiPwd: "",
      thankWord: "",
      lang: 1,
      allowPrintTxn: false,
      mobileDevice: false,
      printAmt: 1,
      timeIn: false,
      timeOut: false,
      customerType: false,
      orderNum: false,
      allowChange: false,
      allowSignature: false,
      allowReciept: false,
    };
    this.vattemplate = data.vattemplate || {
      allowLogo: false,
      allowTitle: false,
      title: "វិក្កយបត្រ (Invoice)",
      allowVat: false,
      allowAddress: false,
      allowPhone: false,
      allowTNum: false,
      allowDate: false,
      allowStore: false,
      allowCounter: false,
      allowCashier: false,
      allowExRate: false,
      allowUom: false,
      allowDis: false,
      allowAmt: false,
      allowQty: false,
      allowPrice: false,
      allowTax: false,
      allowPaid: false,
      note: "",
      wifiPwd: "",
      thankWord: "",
      lang: 1,
      allowPrintTxn: false,
      mobileDevice: false,
      printAmt: 1,
      timeIn: false,
      timeOut: false,
      customerType: false,
      orderNum: false,
      allowChange: false,
      allowSignature: false,
      allowReciept: false,
    };
    this.barcodeDis10 = data.barcodeDis10 || false;
    this.barcodeDis15 = data.barcodeDis15 || false;
    this.barcodeDis20 = data.barcodeDis20 || false;
    this.barcodeDis30 = data.barcodeDis30 || false;
    this.barcodeDis40 = data.barcodeDis40 || false;
    this.barcodeDis50 = data.barcodeDis50 || false;
    this.barcodeDis100 = data.barcodeDis100 || false;
    this.allowRoundUp = data.allowRoundUp || false;
    this.defaultPriceLevel = data.defaultPriceLevel || "";
    this.allowSelectPriceLevel = data.allowSelectPriceLevel || false;
    this.defaultSegment = data.defaultSegment || "";
    this.allowSelectSegment = data.allowSelectSegment || false;
    this.type = data.type || "retail";
    this.orderFlow = data.orderFlow || [];
    this.allowOrderFlow = data.allowOrderFlow || false;
    this.paymentOption = data.paymentOption || [];
    this.userPinData = data.userPinData || [];
    this.orderTypes = data.orderTypes || [];
    this.usedCategory = data.usedCategory || [];
    this.usedCateAdmin = data.usedCateAdmin || [];
    this.usedEmp = data.usedEmp || [];
    this.usedSaleUnit = data.usedSaleUnit || [];
    this.otherFunction = data.otherFunction || [];
    this.defaultCustomer = data.defaultCustomer || {};
    this.allowSelectCustomer = data.allowSelectCustomer || false;
    this.saleUnitFlow = data.saleUnitFlow || 1;
    this.cardFlow = data.cardFlow || 1;
    this.itemFlow = data.itemFlow || 1;
    this.productFlow = data.productFlow || 1;
    this.bankWallet = data.bankWallet || [];
    this.addItemFlow = data.addItemFlow || [];
    this.createdAt = data.createdAt || new Date().toISOString().substr(0, 10);
    this.txnNumber = data.txnNumber || {};
    this.orderNum = data.orderNum || {};
    this.shiftBy = data.shiftBy || 1;
    this.sessionBy = data.sessionBy || 1;
    this.paymentLayout = data.paymentLayout || 1;
    this.sessionForm = data.sessionForm || {
      showTxn: 1,
      showCredit: 1,
      bankW: 1,
      cardP: 1,
      loyaltyCrCard: 1,
      campaign: 1,
      internal: 1,
      loyalty: 1,
      countNote: 1,
      partner: 1,
      orderT: 1,
      guestC: 1,
      saleU: 1,
      preOrder: 1,
      clearServe: 1,
      sendTtxn: 1,
      sendROStock: 1,
      sendNGStock: 1,
      openShift: false,
      requireRate: false,
      isAeon: false,
      disCashierDash: false,
      forceRate: false,
      disTxnList: false,
      txnList: false,
      creitList: false,
      voidList: false,
      returnList: false,
      internalList: false,
      redeemList: false,
      preOrderList: false,
      topUpList: false,
      codeOpenS: false,
      codeEndS: false,
      codeOpenSh: false,
      codeEndSh: false,
    };
    this.checkOutFlow = data.checkOutFlow || 1;
    this.loyaltyProgram = data.loyaltyProgram || {};
    this.rewardProgram = data.rewardProgram || {};
    this.saleUnitStatus = data.saleUnitStatus || 1;
    this.telegramGroup = data.telegramGroup || "";
    this.telCashierGroup = data.telCashierGroup || "";
    this.telStockGroup = data.telStockGroup || "";
    this.telTxnrGroup = data.telTxnrGroup || "";
    this.telCookGroup = data.telCookGroup || "";
    this.rpin = data.rpin || false;
    this.rguestcount = data.rguestcount || false;
    this.rsaleunit = data.rsaleunit || false;
    this.rordertype = data.rordertype || false;
    this.remp = data.remp || false;
    this.localServer = data.localServer || "";
    this.allowLabelP = data.allowLabelP || false;
    this.labelCate = data.labelCate || [];
    this.labelPName = data.labelPName || "";
    this.mirrorDisplay = data.mirrorDisplay || false;
    this.img1 = data.img1 || "";
    this.img2 = data.img2 || "";
    this.img3 = data.img3 || "";
    this.img4 = data.img4 || "";
    this.img5 = data.img5 || "";
    this.khqrMerchantId = data.khqrMerchantId || "";
    this.khqr = data.khqr || "";
    this.allowLoyalty = data.allowLoyalty || false;
    this.allowMemberCard = data.allowMemberCard || false;
    this.allowTopUp = data.allowTopUp || false;
    this.allowGCard = data.allowGCard || false;
    this.allowCatalog = data.allowCatalog || false;
    this.editPrice = data.editPrice || false;
    this.editQTY = data.editQTY || false;
    this.editDis = data.editDis || false;
    this.allowDelItem = data.allowDelItem || false;
    this.allowVoid = data.allowVoid || false;
    this.allowOthOrder = data.allowOthOrder || false;
    this.allowSVoucher = data.allowSVoucher || false;
    this.allowSPrint = data.allowSPrint || false;
    this.allowSPrintCheck = data.allowSPrintCheck || false;
    this.allowSSBill = data.allowSSBill || false;
    this.allowSMBill = data.allowSMBill || false;
    this.allowTax = data.allowTax || false;
    this.taxAmt = data.taxAmt || 1;
    this.allowPLTax = data.allowPLTax || false;
    this.allowSPTax = data.allowSPTax || false;
    this.spTaxRate = data.spTaxRate || 10;
    this.plTaxCate = data.plTaxCate || [];
    this.allowOCharge = data.allowOCharge || false;
    this.oChargeAmt = data.oChargeAmt || 2;
    this.searchCard = data.searchCard || [1, 2, 3];
    this.createCard = data.createCard || [1, 2, 3];
    this.searchProd = data.searchProd || [1, 2, 4, 5];
    this.pointPayOpt = data.pointPayOpt || [1, 2];
    this.pointTUOpt = data.pointTUOpt || [1, 2];
    this.allowRep = data.allowRep || false;
    this.pullDay = data.pullDay || 1;
    this.selectGuestType = data.selectGuestType || false;
    this.quickMode = data.quickMode || false;
    this.autoAddMember = data.autoAddMember || false;
    this.mergeLoyalty = data.mergeLoyalty || false;
    this.allowVoidOldTxn = data.allowVoidOldTxn || false;
    this.noAmtReceipt = data.noAmtReceipt || false;
    this.usedCup = data.usedCup || [];
    this.sheetBy = data.sheetBy || "month";
    this.sheetId = data.sheetId || "";
    this.bankCharge = data.bankCharge || 0;
    this.allowBCharge = data.allowBCharge || 0;
    this.showTExRate = data.showTExRate || false;
    this.allowStax = data.allowStax || false;
    this.sTax = data.sTax || 0;
    this.allConTxn = data.allConTxn || false;
    this.bankCName = data.bankCName || "";
    this.suReuse = data.suReuse || false;
    this.usedCard = data.usedCard || [];
    this.linkRegister = data.linkRegister || ''
  }

  constuct(data) {
    this.constructor(data);
  }
}
