export default class PosTransaction {
    constructor(data = {}) {
        this.id = data.id || ''
        this.total = data.total || 0
        this.subTotal = data.subTotal || 0
        this.discount = data.discount || 0
        this.modiAmt = data.modiAmt || 0
        this.tax = data.tax || 0
        this.itemLines = data.itemLines || ''
        this.customer = data.customer || {}
        this.priceLevel = data.priceLevel || {}
        this.orderNumber = data.orderNumber || ''
        this.lastOrderNum = data.lastOrderNum || 1
        this.session = data.session || {}
        this.register = data.register || ''
        this.store = data.store || ''
        this.pinUser = data.pinUser || {}
        this.guestCount = data.guestCount || {}
        this.saleUnit = data.saleUnit || {}
        this.loyaltyCard = data.loyaltyCard || {}
        this.reward = data.reward || {}
        this.orderType = data.orderType || {}
        this.partner = data.partner || {}
        this.amtReceipt = data.amtReceipt || 0
        this.paidBy = data.paidBy || 'Cash'
        this.exchange = data.exchange || {base: 0, secondary: 0}
        this.exchangeRate = data.exchangeRate || ''
        this.issuedDate = data.issuedDate || new Date()
        this.type = data.type || 'sale'
        this.cardPay = data.cardPay || {}
        this.bankPay = data.bankPay || {}
        this.txnNumber = data.txnNumber || ''
        this.lastNumber = data.lastNumber || 1
        this.orderDate = data.orderDate || new Date()
        this.billDate = data.billDate || ''
        this.otherCharge = data.otherCharge || 0
        this.point = data.point || {amount: 0, type: 'earn'}
        this.campaigns = data.campaigns || [] 
        this.isSale = data.isSale || 0
        this.status = data.status || 1
        this.txnId = data.txnId || ''
        this.pointEarn = data.pointEarn || {}
        this.pointPaid = data.pointPaid || {}
        this.warehouseId = data.warehouseId || ''
        this.phoneNumber = data.phoneNumber || ''
        this.isEdit = data.isEdit || false
        this.editDate = data.editDate || ''
        this.isTopUpCounter = data.isTopUpCounter || false,
        this.isTopUpGCard = data.isTopUpGCard || false,
        this.isSaleGCard = data.isSaleGCard || false,
        this.isSaleVoucher = data.isSaleVoucher || false,
        this.isSaleCard = data.isSaleCard || false
        this.saleCardAmount = data.saleCardAmount || 0
        this.comission = data.comission || 0
        this.timeIn = data.timeIn || ''
        this.timeOut = data.timeOut || ''
        this.employee = data.employee || ''
        this.paymentMethod = data.paymentMethod || 'cash'
        this.isCredit = data.isCredit || false
        this.creditCard = data.creditCard || {}
        this.orderFrom = data.orderFrom || 'pos'
        this.giftCard = data.giftCard || {}
        this.voucher = data.voucher || {}
        this.khrPaidAmt = data.khrPaidAmt || 0
        this.usdPaidAmt = data.usdPaidAmt || 0
        this.plTax = data.plTax || 0
        this.spTax = data.spTax || 0
        this.vat = data.vat || 0
        this.shift = data.shift || 1
        this.instId = data.instId || 'NONE'
        this.qrCode = data.qrCode || ''
        this.stockItems = data.stockItems || []
        this.voidAmount = data.voidAmount || 0
        this.discountCard = data.discountCard || {}
        this.guestType = data.guestType || ''
        this.invoiceType = data.invoiceType || 'normal'
        this.bankPaidAmt = data.bankPaidAmt || 0
        this.otherPaidAmt = data.otherPaidAmt || 0
        this.bankPays = data.bankPays || []
        this.otherPays = data.otherPays || []
        this.returnAmt = data.returnAmt || 0
        this.memo = data.memo || ''
        this.amtRedeem = data.amtRedeem || 0
        this.redeemPoint = data.redeemPoint || 0
        this.bankCharge = data.bankCharge || 0
        this.payNature = data.payNature || 'Cash'
        this.sessionDate = data.sessionDate || ''
        this.bankName = data.bankName || ''
        this.txnQr = data.txnQr || ''
        this.cardItem = data.cardItem || {}
    }

    constuct(data) {
        this.constructor(data);
    }
}